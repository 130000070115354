<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-shield"></i> 安全巡检 | 分类设置</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <!-- <div class="w-block" style="padding: 2px;">
                        </div> -->
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX + 1" @page-changed="loadPage">

                            <el-table-column label="日期" width="150">
                                <template slot-scope="scope" align="center">
                                    {{ scope.row.CREATED_DT | datetime('YYYY-MM-DD') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="Safe_Class_Name" label="巡检类别" width="150" align="center" />

                            <el-table-column label="周期">

                                <template slot-scope="scope">
                                    <el-select v-model="scope.row.Frequency" size="medium">
                                        <el-option v-for="(item, index) in date" :label="item.NAME" :value="item.VALUE"
                                            :key="index"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>

                            <el-table-column label="操作" width="100" align="center">

                                <template slot-scope="scope">
                                    <el-button type="text" @click="edit(scope.row)">保存</el-button>
                                </template>
                            </el-table-column>

                        </wgrid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Wgrid from "@/components/wgrid";
export default {
    components: { Wgrid },
    data() {
        return {
            grid: {
                sea: {
                    PAGE_SIZE: 12,
                    PAGE_INDEX: 0,
                },
                ls: [],
                total: 0,
                loading: false
            },
            date: [{ NAME: '日', VALUE: 'DAY' }, { NAME: '周', VALUE: 'WEEK' }, { NAME: '半月', VALUE: 'HALFMONTH' }, { NAME: '月', VALUE: 'MONTH' }]
        }
    },
    created() {
        this.filter()
    },
    methods: {
        filter() {
            this.getList()
        },
        loadPage() { },
        getList() {
            let self = this;
            this.whale.remote.getCollection({
                url: '/api/School/SAFE/SafeClassConfig/GetPageList',
                completed(its) {
                    console.log('its', its)
                    self.grid.ls = its
                }
            })
        },
        edit(row) {
            let self = this;
            this.whale.remote.getCollection({
                url: '/api/School/SAFE/SafeClassConfig/Update',
                data: {
                    ID: row.ID,
                    Safe_Class_Name: row.Safe_Class_Name,
                    Frequency: row.Frequency,
                },
                completed() {
                    self.getList()
                }
            })
        },
        del() { }
    },
}
</script>

<style></style>